<template>
  <div>
    <!--
    <titleBar
      v-show="!isBotSettingsHome"
      :title="titleBarTitle"
      back="auto"
      :showBackArrowDesktop="true"
      subtitle=""
      :centered="false"
      theme="white"
      :showSignup="false"
      containerClass="donotuse"
      :showDesktop="true"
      :showAfterScroll="0"
      :scrollTopOnClick="true"
      :inline="false"
      actionButtonStyle="round"
      actionButtonRoundBg="transparent"
      iconPack="fas"
      
    />      <titleBar
      :title="'My bots'"
      :img="bot?.avatar"
      back="auto"
      :showBackArrowDesktop="true"
      subtitle=""
      :centered="false"
      theme="white"
      :showSignup="false"
      containerClass="donotuse"
      :showDesktop="true"
      :showAfterScroll="0"
      :scrollTopOnClick="true"
      :inline="false"
      actionButtonStyle="round"
      actionButtonRoundBg="transparent"
      iconPack="fas"
    />-->

    <titleBar
      :title="''"
      back="auto"
      :showBackArrowDesktop="true"
      subtitle=""
      :centered="false"
      theme="white"
      :showSignup="false"
      containerClass="donotuse"
      :showDesktop="true"
      :showAfterScroll="0"
      :scrollTopOnClick="true"
      :inline="false"
      actionButtonStyle="round"
      actionButtonRoundBg="transparent"
      iconPack="fas"
    />

    <div class="section" :class="{ isBotSettingsHome: isBotSettingsHome, notHome: !isBotSettingsHome }">
      <div class="container w600" v-if="bot" style="max-width: 300px">
        <div class="intro-card" style="text-align: center">
          <router-link :to="`${bot.url}`">
            <div class="message-avatar">
              <img :src="bot?.avatar" alt="" class="avatar" />
            </div>

            <div class="message-handle">
              <h2 class="title is-6" style="margin-bottom: 4px">{{ bot?.handle }}</h2>

              <!--  
       
              View Profile
           
            --></div>
          </router-link>

          <router-link :to="bot.url + '/manage/plan'" class="icon-link">
            <span class="tag is-danger is-light is-small is-mini">{{ bot.planName || "Free plan" }} </span>
          </router-link>
        </div>

        <br />
        <br />
        <b-button
          type="is-primary"
          icon-left="sparkles"
          icon-pack="fas"
          expanded
          tag="router-link"
          :to="'/' + bot?.handle + '/new'"
          >Create New Post</b-button
        >
        <div class="columns is-mobile">
          <div class="column">
            <router-link :to="bot.url" class="icon-link">
              <b-icon icon="user-circle" pack="fal" size="is-large"></b-icon>
              <div>View page</div>
            </router-link>
          </div>
          <div class="column">
            <router-link :to="bot.url + '/manage/insights'" class="icon-link">
              <b-icon icon="analytics" pack="fal" size="is-large"></b-icon>
              <div>Insights</div>
            </router-link>
          </div>
          <div class="column">
            <router-link :to="bot.url + '/manage'" class="icon-link">
              <b-icon icon="cogs" pack="fal" size="is-large"></b-icon>
              <div>Configure</div>
            </router-link>
          </div>
        </div>
        <br />
        <br />
        <br />
        <br />
        <jsonDebug :data="bot" label="bot" style="max-width: 500px" />

        <!--      
        <br />
        <div class="menuSettings">
          <div v-for="i in menu" :key="i.to">
            <router-link :to="i.to" class="button is-outlined is-dark is-fullwidth" style="margin-bottom: 10px">
              {{ i.label }}
            </router-link>
          </div>

          <div class="is-hidden-touch">
            <div class="columns">
              <div class="column">
                <b-button type="is-info is-outlined is-fullwidth" @click="save" :loading="posting" :disabled="posting"
                  >View Profile</b-button
                >
              </div>
              <div class="column">
                <b-button
                  type="is-danger is-outlined  is-fullwidth"
                  @click="save"
                  :loading="posting"
                  :disabled="posting"
                  >Share</b-button
                >
              </div>
            </div>
          </div>
        </div> -->
      </div>

      <!--
    <v-row no-gutters>
      <v-col v-if="!isMobile || isBotSettingsHome" cols="3">
        <v-list>
          <v-list-item
            v-for="(interlocutor, index) in interlocutors"
            :key="index"
            :to="{ name: 'inbox-thread', params: { conversationId: interlocutor.conversationId } }"
          >
            <v-list-item-avatar>
              <v-icon>mdi-account-circle</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ interlocutor.name }}</v-list-item-title>
              <v-list-item-subtitle>{{ interlocutor.lastMessage }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>

      <v-col v-if="!isBotSettingsHome || (isMobile && interlocutors.length === 0)" cols="12" sm="9">
        <router-view></router-view>
      </v-col>
    </v-row> -->
    </div>
  </div>
</template>

<script>
import JsonDebug from "@/components/e/jsonDebug.vue";
import inboxList from "@/components/inbox/InboxList.vue";
import inboxThread from "@/components/inbox/inboxThread.vue";

export default {
  metaInfo() {
    return {
      title: this.title,
    };
  },
  name: "BotSettings",
  components: {
    // inboxList,
    //inboxThread,
    //  JsonDebugt,
  },
  data() {
    return {
      // featuredTags,
      interlocutors: [],
      convos: [],
      convo: null,
      tasks: [],
      bot: null,
      //keyFeatures,
      // projs: [],
      loading: true,
      posting: false,
      // loadingOps: true,
    };
  },
  mounted() {
    this.loadProfile();
    /*window.API.getFeaturedBots().then((data) => {
      this.interlocutors = data;
      this.loading = true;
      this.loadThread(this.$route.params.bot, this.$route.params.convo);
      // console.log("PROJJ!", proj, proj.id);
    });*/
  },
  watch: {
    $route(to, from) {
      //  this.loadThread(to.params.bot, to.params.convo);
    },
  },
  methods: {
    loadProfile(index) {
      var id = this.$route.params.bot;

      var opt = {};
      // if is admin, add moderator option.
      /*
      if (this.$store.main.getters.isAdmin) {
        opt = { moderator: true };
      }*/

      var isAdmin = this.$store.main.getters.isAdmin;
      var method = isAdmin ? "botProfileAdmin" : "botProfileOwner";
      window.API[method](id, opt).then((b) => {
        this.bot = b;
        this.loadingBots = false;
        // console.log("PROJJ!", proj, proj.id);
      });
    },
    save: function (event) {
      this.posting = true;
      // `this` inside methods point to the Vue instance
      var d = this.bot;
      // alert("Hello " + d + "!");
      // `event` is the native DOM event
      console.log(d);

      // d = { H3334: 343254354354353, id: "dashApi" };

      let method;
      //ad is admin
      if (this.$route.name == "BotManageModerate" && this.$store.main.getters.isAdmin) {
        method = "moderateBot"; //PUT, for admins only
      } else {
        method = "editBot";
      }

      window.API[method](d.handle, d).then((bot) => {
        this.posting = false;
        //snackbar black buefy
        this.$buefy.snackbar.open({
          message: "Changes saved",
          type: "is-success",
          position: "is-bottom-right",
          actionText: "Close",
          indefinite: false,
        });

        //bot updated!
      });
    },
  },
  computed: {
    menu() {
      var h = this.$route.params.bot;
      const base = "/" + h + "/manage/";
      var menu = [
        {
          label: "Create New Post",
          style: "primary",
          icon: "fal fa-plus",
          to: "/" + h + "/new",
        },

        /*  */ {
          label: "View Profile",
          icon: "fal fa-user",
          to: base + "viewProfile",
        },
        {
          label: "Page Settings",
          icon: "fal fa-cog",
          to: h + "/manage",
        },

        /*
        {
          label: "Insights",
          icon: "fal fa-chart-bar",
          to: base + "insights",
        },
        {
          label: "Integrations",
          icon: "fal fa-plug",
          to: base + "integrations",
        }
        {
          label: "Monetize",
          icon: "fal fa-money-bill",
          to: base + "monetize",
        },
        {
          label: "Search",
          icon: "fal fa-search",
          to: base + "search",
        },
        {
          label: "Moderate",
          icon: "fal fa-user-shield",
          to: base + "moderate",
          isAdmin: true,
        },
        {
          label: "Delete",
          icon: "fal fa-trash",
          to: base + "delete",
          isAdmin: true,
        },,*/
      ];

      // filter out admin only items
      menu = menu.filter((m) => {
        if (m.isAdmin && !this.$store.main.getters.isAdmin) return false;
        return true;
      });

      return menu;
    },
    isBotSettingsHome() {
      return (
        this.$route.name === "BotSettings" ||
        this.$route.name === "BotSettingsHome" ||
        this.$route.name === "BotSettingsEmpty"
      );
    },
    isNewMessage() {
      return this.$route.name === "inboxNewConvo" || this.$route.name === "inboxNewConvoTask";
    },
    interlocutorsShown() {
      //filter and add some props like "active., typing, etc..."
      return this.interlocutors;
      // Return a list of interlocutors here based on some state or props
    },

    newRoute() {
      return this.isNewMessage;
    },
    isMobile() {
      return true;
      // Return a boolean indicating whether the user is on a mobile device
    },
    titleBarTitle() {
      if (this.isBotSettingsHome) return "Chats";
      if (this.isNewMessage) return this.$route.params.bot + " ✨ ";
      if (this.bot) return this.bot.handle;
      var rb = this.$route.params.bot;
      if (rb) return rb;
      return ""; //prevent flickering, but let user go back while slow load.
    },
    title() {
      return "" + this.titleBarTitle + " / manage";
    },
  },
};
</script>

<style scoped>
.zone {
  /* 
  border-radius: 50px;
  background-color: white;
  border: 1px #ccc solid;*/
}

.zone.list {
  /*
  border-radius: 50px;
   background-color: white;
  */
}

/* mobiel selective columns depending on class isBotSettingsHome using media query*/

.inboxList {
  max-width: 300px;
}
@media only screen and (max-width: 1022px) {
  .isBotSettingsHome .zone.convo {
    display: none;
  }
  .notHome .zone.list {
    display: none;
  }
  .inboxList {
    max-width: 999px;
  }
}

img.avatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin: auto;
}
.columns {
  margin-top: 20px;
}
.column {
  text-align: center;
}
.icon-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
</style>
